@use "@angular/material" as mat;
@use "./themes_theme-colors.scss" as my-theme;

.darkTheme {
  color-scheme: dark;
  --logo-background-color: transparent;
  --background-color: #212121;
  --light-background-color: #424242;
  --dark-background-color: var(--background-color);
  --background-overlay: rgba(0, 0, 0, 0.7);
  --text-color: #f5f5f5;
  --light-text-color: #dfdfdf;
  --icon-color: #ffffff;
  --light-icon-color: #afafaf;
  --select-color: #3b3b3b;
  --hover-color: #323232;
  --table-background: #313131;
  --table-blue-background: #1e2738;
  --table-grey-background: #383838;
  --light-table-background: #222222;
  --link-color: #3885ff;
  --card-background-color: var(--background-color);
  --card-active-background-color: var(--light-background-color);
  --backdrop-color: linear-gradient(349.75deg, rgba(20, 35, 77, 0.72) 43.51%, rgba(102, 102, 102, 0.368) 152.13%);
  --login-logo-gradient: linear-gradient(to right, rgba(255, 255, 255, 0.8), transparent);
  --invert-filter: invert(1);

  // Button styling
  --button-disabled-shade-1: #595959;
  --button-disabled-shade-2: #ababab;
}
