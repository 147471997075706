@use "@angular/material" as mat;
@use "./themes_theme-colors.scss" as my-theme;

html {
  @include mat.theme(
    (
      color: (
        primary: my-theme.$primary-palette
      ),
      typography: Poppins
    )
  );
}

.lightTheme {
  color-scheme: light;
  --logo-background-color: #abcbff;
  --background-color: #f5f7fc;
  --dark-background-color: #eaeef8;
  --light-background-color: #ffffff;
  --background-overlay: transparent;
  --text-color: #212121;
  --light-text-color: #7e7e7e;
  --icon-color: #3c4858;
  --light-icon-color: #bdbdbd;
  --select-color: #d0ddfd;
  --hover-color: #e1e1e1;
  --table-background: #f2f6ff;
  --table-blue-background: #ecf4fa;
  --table-grey-background: #eeeeee;
  --light-table-background: #f9fafc;
  --link-color: #3885ff;
  --card-background-color: var(--light-background-color);
  --card-active-background-color: var(--background-color);
  --backdrop-color: linear-gradient(349.75deg, rgba(62, 114, 255, 0.72) 43.51%, rgba(255, 255, 255, 0.368) 152.13%);
  --login-logo-gradient: transparent;
  --invert-filter: invert(0%);

  // Button styling
  --button-disabled-shade-1: rgba(0, 0, 0, 0.12);
  --button-disabled-shade-2: rgba(0, 0, 0, 0.38);
}
